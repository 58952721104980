.hero {
    background-image: url("../imgs/home_bg.jpg");
    height:80vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position:bottom;
  }

.hero-mobile {
    display:none;
}

@media (min-width: 1440px) {
  .hero {
    background-image: url("../imgs/home_bg.jpg");
    height:100vh;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}


@media (max-width: 1080px) {
    .hero {
      background-image: none;
      height:auto;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .hero-mobile {
      display:block;
      width:100%;
    }
}
