.pill-text {
    font-weight: 100;
    font-size: 0.875rem;
    color: #114B5F!important;
    background-color: #F4FCFE;
  }
  
.pill-text-outline {
    font-weight: 100;
    font-size: 0.875rem;
    color: #43B0B1;
    border: 1px solid #43B0B1;
    background-color: #F4FCFE;
}
  
.pill-text-active {
    font-weight: 100;
    font-size: 0.875rem;
    color: #FFFFFF;
    background-color: #43B0B1;
}

.input-group-text {
    background-color:#FFFFFF!important;
    color: #43B0B1!important;
}

.border-color {
    border: 1px solid #FFFFFF!important;
}

.list-avatar-img {
    max-width:135px;
}

.qa-text {
    color:#000000;
    text-decoration: none!important;
}
  
.qa-text:hover {
   color:#43B0B1!important;
}

/* copied from before? */
.filter-btn{
    display: inline-block!important;
    padding: 2px 12px!important;
    background: #fff;
    border-radius: 20px;
    color: #43B0B1;
    border: 1px solid #43B0B1;
    height: 32px;
    margin-bottom: 8px;
    cursor: pointer;
}

.filter-btn-selected{
    background: #43afb1c5;
    color: #fff;
}

.filter-btn:hover, .filter-btn:focus{
    background: #43afb1c5;
    color: #fff;
}

.square-btn{
    display: inline-block!important;
    padding: 2px 12px!important;
    background: #fff;
    border-radius: 4px;
    color: #43B0B1;
    border: 1px solid #43B0B1;
    margin-bottom: 8px;
    cursor: pointer;
    width: 90px;
}

.square-btn-solid{
    display: inline-block!important;
    padding: 2px 12px!important;
    background: #43B0B1;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #43B0B1;
    margin-bottom: 8px;
    cursor: pointer;
    width: 90px;
}

.hidden{
    position:absolute;
    visibility:hidden;
    opacity:0
}

input[type="checkbox"]:checked + label{
    content: none;
    color: #fff!important;
    background: #43B0B1!important;
    margin-left: 0;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    top: 100%;
    left: 55px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown-content a:hover {background-color: #f1f1f1}

@media (max-width: 796px) {
  .list-avatar-img {
    max-width:80px;
  }
}

ul.demo {
  list-style-type: none!important;
  margin: 0;
  padding: 0;
}

.upperline {
  background-color: #FFFFFF;
  box-shadow: 0px -1px 0px #E7E9EE;
}

.max-width-960 {
    max-width: 960px!important;
}

.max-width-30 {
    max-width:30%!important;
}