.badge {
    width: 80px;
    font-size: 14px; 
    font-weight: 300;
    margin-left: 2%;
}

.bg-success {
    background-color:  #ff6347!important;
}

.bg-info {
    background-color:  #8d68f8!important;
}

.bg-dark {
    background-color:  #51484f!important;
}

.bg-primary {
    background-color: #43B0B1!important;
}

.bg-secondary {
    background-color: white!important;
    color: #43B0B1;
    border: 1px solid #43B0B1;
}

.filterBar .badge {
    cursor: pointer;
}

.pinned-text-highlight {
    color: #ff6347;
    display: inline-block;
}

.card-text-large {
    font-size: 18px;
    font-weight: 500;
}