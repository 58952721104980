/* No normal bullets please */
.event-desc ul {
  list-style-type: none;
}

.event-desc li {
  /* You need to turn on relative positioning so the line is placed relative to the item rather than absolutely on the page */
  position: relative;
  
  /* Use padding to space things out rather than margins as the line would get broken up otherwise */
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;
  color: #43B0B1;
  font-weight: 300;
}

/* The actual line being placed before each list item, tweak width and color as appropriate */
.event-desc li:before {
  background-color: #ADD8E6;
  width: 1px;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 5px;
}
    
/* Start the line further down on the first list item */
.event-desc li:before { 
    top: 20px;
    bottom: -6px;
}

/* 
Stop the line short on the final list item 
.event-desc li:last-child:before { height: 6px; }
*/

    
/* Styles for the SVG bullet points */
.event-desc .bullet { 
    margin-left: -21px; 
    width: 12px;
    float: left; 
    padding-right: 10px 
}

.event-desc .bullet-between {
    color: black;
    font-size: 15px;
    padding-top: 12px;
}

.attribute-btn{
    display: inline-block!important;
    padding: 2px 12px!important;
    background: #EBECF0;
    border-radius: 20px;
    color: #000000;
    border: 1px solid #EBECF0;
    margin-bottom: 8px;
    cursor: pointer;
    margin-right: 16px;
    font-size: 13px;
}

.attribute-btn:hover, .attribute-btn:focus{
    background: #43afb1c5;
    color: #fff;
}
