.calendar-cell {
    background: #fff;
    border-radius: 2px;
    color: #43B0B1;
    border: 1px solid #43B0B1;
    cursor: pointer;
/*     height:24px; */
    margin-bottom:4px;
}

.calendar-cell:hover, .calendar-cell:focus{
    background: #43afb1c5;
    color: #fff;
}

.white-box {
  background-color: #FFFFFF;
}

.overflow-x{
  min-width:375px;
  overflow-x: scroll;
}

.overflow-y {
  max-height:400px;
  overflow-y: scroll;
}

.avatar {
  height: 40px;
}

.no-bullet {
  list-style-type: none!important;
}

.grey-box {
  background-color:#FAFAFA;
}

.detail-description {
  font-family: 'Noto Sans SC', sans-serif;
  line-height: 1.7;
  font-weight: 220;
  font-size: 17px;
  text-align: start;
  white-space: normal;
}

@media (max-width: 767px) {
  .detail-description {
    font-size: 1rem; 
    font-weight: 300;
  }
}

.young-counselor-intro-title-text{
  font-size: 28px;
  font-weight: 300;

}

.profile-image {
  width: 60%;
  padding-bottom: 8px;
  padding-top: 10px;
}

.profileCard {
  border: solid 1px #FFFFFF;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px;
  height: 800px;
  overflow: hidden;
  box-shadow: 1px 2px 3px 2px rgba(12, 12, 12, 0.154);
}

@media (max-width: 1019px) {
  .white-box {
    background-color: #FAFAFA;
  }
}