.logo {
    width: 80px;
}

.avatar {
    width: 40px;
}

.navbar-toggler:focus {
    box-shadow: none!important;
} 
  
.navbar-toggler:hover {
    background-color: #ffffff;
    color:#166b6b;
}
