.location-rate-icons {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.row h5 {
    color: #43B0B1;
    font-weight: 500;
}

.pill {
    background-color: #FFE8C5;
    border-radius: 30px;
    padding: 8px;
    margin: 8px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;
    display: inline-flex;
    font-size: 14px;
    font-weight: 300;
}

.profile-image {
    width: 80%;
    padding-bottom: 8px;
    padding-top: 10px;
}