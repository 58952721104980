.talk_to_modal {
    height: 560px;
    width: 100%;
}

.card-img {
    max-width: 480px;
}

.dot {
    height: 56px;
    width: 56px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  
.card-dark {
    background-color:#114B5F;
    border-radius: 8px;
}
  
.grey-box {
    background-color:#FAFAFA;
    border-radius: 8px;  
}

.carousel-inner {
    align-self: center;
    display: flex;
    align-items: center;
}

.carousel-style {
    min-height: 140px;
    display: flex;
}
  
.brand {
    max-width: 160px;
}

.icon {
    width:40px;
    opacity:0.85;
}

.modal-dialog {
    max-width: 680px!important;
}

.tawkto-modal {
    height:80vh;
}

@media (max-width: 796px) {
    .brand {
        max-width: 80px;
    }
}

.chat-list {
    width: auto;
}
