.form-card {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(229, 229, 229, 0.5), 0px 2px 2px rgba(229, 229, 229, 0.5);
    border-radius: 8px;
    max-width:480px;
}

.large-form-card {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(229, 229, 229, 0.5), 0px 2px 2px rgba(229, 229, 229, 0.5);
    border-radius: 8px;
    max-width:800px;
}
.border-right {
    border-right:none!important;
}
  
.border-left {
    border-left:none!important;
}

.input-group-text {
    background-color:#FFFFFF!important;
    color: #43B0B1!important;
}

.active {
  color: #114B5F!important;
  text-decoration:none;
}

.inactive {
  color: #B0B0B0!important;
  text-decoration:none;
}

.nav-link:hover{
  color:#43B0B1!important;
}
