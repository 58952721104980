
.therapist_profile_list ul, .therapist_profile_list li{
    list-style: none;
    padding: 0;
}
  
.therapist_profile_list li{
    padding-bottom: 1.5rem;
    border-left: 1px solid #E7E9EE;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
  }

.therapist_profile_list li:last-child{
      border: 0px;
      padding-bottom: 0;
  }
  
.therapist_profile_list li::before{
      content: '';
      width: 9px;
      height: 9px;
      background: #6FE4E5;
      border-radius: 2px;
      position: absolute;
      left: -5px;
      top: 0px;
    }

.breadcrumb-link {
    text-decoration:none;
    cursor:pointer;
    color:#071E22;
}


.link:hover,.breadcrumb-link:hover {
    text-decoration:none;
    color:#114B5F!important;
}

.link {
    text-decoration:none;
    cursor:pointer;
    color:#43B0B1;
}
  
  .box {
    border: 1px solid #cccccc;
  /*   padding: 16px; */
  }
  
  .banner-box {
    color: #ffffff;
    background-color: #114B5F;
    overflow:visible;
  }

.banner-text {
    max-width: 600px;
}
  
  .reservation-box {
    color: #071E22;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    max-width: 700px;
  }
  
  .list-avatar-img {
    max-width:80px;
  }
  
  
  @media (min-width: 1020px) {
    .hide-web {
      display:none;
    }
    .banner-box {
      max-height: 300px;
    } 
  
  }
  
  
  @media (max-width: 1019px) {
    .hide-mobile {
      display:none;
    }
  }
  
  