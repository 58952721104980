.avatar {
    width: 40px;
}

.avatar-big {
    width: 80px;
}

.user-profile-link {
  color: #071E22!important;
}

.user-profile-link:hover {
  box-shadow:none!important;
  color: #071E22!important;
  background-color: #F4FCFE!important;
}

.user-profile-link.active {
  color: #43B0B1!important;
  font-weight:bold;
  box-shadow:none!important;
  background-color: #F4FCFE!important;
}

.box-shadow {
  box-shadow: 0px 2px 0px 0px #43B0B1;
}
